import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axiosClient from "../components/AxiosClient";
import "react-toastify/dist/ReactToastify.css";
import { BsCartPlus } from "react-icons/bs"; // Import the cart icon
import med from "../assets/med.png"; // Assuming this is the image path
import presImage from "../assets/pres.jpeg";
import { IoPaperClip } from 'react-icons/io5'; // or any other icon you prefer
import noteContext from "../context/notes/noteContext";

const Tablet = (props) => {
    console.log(props, "props");
  
    const [requiredQuantity, setRequiredQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const { checkTokenExpiry, cartcount, setcartcount } = useContext(noteContext);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [file, setFile] = useState(null); // State for file upload
    const navigate = useNavigate();
    const vendor_id = props.vendor.vendor_id;
    const product_id = props.vendor._id;
    const product_main_id = props.productId;
    const discount = props.vendor?.smart_deal?.discount_percentage| 0;
    const Mrp = props.vendor?.Mrp;
    const discountPrice = props.vendor?.Mrp - props.vendor?.Mrp * (discount / 100);
    const availableQuantity = props.vendor.available_quantity; // Assuming the available quantity is coming from props
    console.log(discountPrice,discount, "090909")
    // Function to handle coupon button click
    const handleGetCoupon = () => {
        if (requiredQuantity > availableQuantity) {
            toast.error(`Only ${availableQuantity} units available.`);
            return;
        }
        setShowModal(true); // Show prescription upload modal if quantity is valid
    };

    const getAuthConfig = () => {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");
        checkTokenExpiry();
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        };
    };

    // Function to handle file upload
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Function to handle prescription file upload
    const handleUpload = () => {
        console.log(discountPrice, "discountPrice")
        if (!file) {
            toast.error("Please upload a prescription file.");
            return;
        }

        // Simulate file upload success
        toast.success("Prescription uploaded successfully!");
      
        // Navigate to getCoupons with the relevant data
        navigate("/getCoupons", {
            state: {
                prescription_file: file,
                product_id: product_id,
                vendor_id: vendor_id,
                quantity: requiredQuantity,
                discount: discountPrice,
                discounted_price: props.vendor?.smart_deal?.discount_percentage,
                mrp: Mrp,
                name: props.name,
                smart_delas:props.vendor?.smart_deal,
                packaging: props.packaging,
                availableQuantity: props.available_quantity,
            },
        });

        setShowModal(false); // Close the modal after upload
    };

    const addtocart = async (requiredQuantity) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error("Please login to get a coupon.");
                return;
            }

            const formData = new FormData();
            formData.append('product_id', product_id);
            formData.append('quantity', requiredQuantity);
            formData.append('vendor_id', vendor_id);
            formData.append('product_main_id', product_main_id);
            formData.append('quantity', requiredQuantity);

            const response = await axiosClient.post(
                '/cart/add_cart',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log(response); // Log the response for debugging

            if (response.status === 200) {
                toast.success("Cart created successfully.");
            } else {
                toast.error("Failed to Add Cart.");
            }

        } catch (error) {
            console.error('Error creating coupon:', error);
            toast.error('An error occurred while creating the coupon. Please try again later.');
        }
    };

    // Function to handle adding item to cart
    const handleAddToCart = () => {
        console.log("Adding to cart..."); // Add this line for debugging
        //addtocart(1); // Try adding a fixed quantity of 1 for testing
        if (requiredQuantity > availableQuantity) {
            toast.error(`Cannot add more than ${availableQuantity} units to cart.`);
            return;
        }
        addtocart(requiredQuantity);
    };

    // Function to handle skip action
    const handleSkip = () => {
        console.log(props.vendor?.smart_deal?.discount_percentage, "090909")
        setShowModal(false); // Close the modal
        // Navigate to getCoupons or any other action you want to perform
        navigate("/getCoupons", {
            state: {
                product_id: product_id,
                vendor_id: vendor_id,
                quantity: requiredQuantity,
                discount: discountPrice,
                discounted_price: props.vendor?.smart_deal?.discount_percentage,
                mrp: Mrp,
                name: props.name,
                packaging: props.packaging,
                availableQuantity: props.available_quantity,
            },
        });
    };

    return (
        <div>
            <div className="flex flex-col md:flex-row justify-between mt-2 bg-gray-100 border border-orange-500 rounded-lg px-5 py-5 shadow-md">
    <div className="flex items-center">
        <img src={med} style={{ width: "50px", height: "50px" }} alt="Medicine" />
        <div className="ml-3">
            <div className="font-bold text-lg">{props.name}</div>
            <div>Packaging Type: {props.packaging}</div>
            <div>Available Quantity: {availableQuantity}</div>
            <input
                placeholder="Enter required quantity"
                type="number"
                min="1"
                value={requiredQuantity}
                onChange={(e) => setRequiredQuantity(parseInt(e.target.value))}
                className="border border-gray-300 rounded-md px-2 py-1 mt-1 w-full md:w-auto" // Full width on mobile
            />
        </div>
    </div>
    <div className="w-full md:w-4/12 text-left mt-4 md:mt-0"> {/* Full width on mobile and a fixed width on larger screens */}
        <div className="text-red-500 line-through">{props.currency} {Mrp}</div>
        <div className="text-green-500 font-semibold">{props.currency} {discountPrice.toFixed(2)}</div>
        <div className="text-gray-600">(save {props.save}%)</div>
        <div className="flex items-center justify-start mt-2">
        <button
                    onClick={handleAddToCart}
                    className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-orange-400 to-yellow-400 text-white transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                >
                    <BsCartPlus size={24} /> {/* Cart Icon */}
                </button>
            {/* <div className="ml-2 flex items-center">
                <button
                    onClick={handleAddToCart}
                    className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-orange-400 to-yellow-400 text-white transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                >
                    <BsCartPlus size={24} /> {/* Cart Icon */}
                {/* </button> */}
            {/* </div> */} 
        </div>
    </div>
</div>

            {/* Modal for uploading prescription */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg p-6 shadow-lg z-50 flex flex-col items-center"> {/* Centered content */}
                        <img src={presImage} alt="Prescription" className="mb-4 w-1/2 mx-auto" /> {/* Image on top */}
                        <h2 className="text-2xl mb-4 text-center">Upload Prescription</h2> {/* Centered title */}
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="mb-4 border border-gray-300 rounded-md p-2"
                        />
                        <div className="flex space-x-4">
                            <button
                                onClick={handleUpload}
                                className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg" // Added hover effects
                            >
                                Get Coupon
                            </button>
                            <button
                                onClick={handleSkip} // Skip button
                                className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg" // Added hover effects
                            >
                                Skip
                            </button>
                            <button
                                onClick={() => setShowModal(false)} // Close modal
                                className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer position="top-right" autoClose={5000} />
        </div>
    );
};

export default Tablet;

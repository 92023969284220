import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axiosClient from "../components/AxiosClient"; // Adjust the import based on your file structure
import { toast } from "react-toastify"; // Optional, if you want to show notifications
import noteContext from "../context/notes/noteContext";

const Profile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { checkTokenExpiry } = useContext(noteContext);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    throw new Error("Token not found");
                }
                checkTokenExpiry();
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };

                const response = await axiosClient.post("/users/userdata", null, config);

                if (response.status === 200) {
                    setUser(response.data.data[0]);
                } else {
                    throw new Error("Failed to fetch user data");
                }
            } catch (err) {
                console.error("Error fetching user data:", err);
                setError(err.message);
                toast.error("Error fetching user data");
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Navbar />
            <div className="text-center font-semibold text-2xl mt-5">My Profile</div>
            {user && (
                <div className="flex flex-col items-center mt-5">
                    <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                        <div className="flex justify-between">
                            <div className="font-bold">Name</div>
                            <div>{user.name}</div>
                        </div>
                    </div>
                    <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                        <div className="flex justify-between">
                            <div className="font-bold">Email</div>
                            <div>{user.email}</div>
                        </div>
                    </div>
                    <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                        <div className="flex justify-between">
                            <div className="font-bold">Phone Number</div>
                            <div>{user.mobile}</div>
                        </div>
                    </div>
                    {user.subscription === true && (
                        <div className="w-full max-w-md shadow-lg rounded-md px-5 py-2 mb-5">
                            <div className="flex justify-between">
                                <div className="font-bold">Subscription</div>
                                <div>You have bought a subscription plan</div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Footer />
        </div>
    );
};

export default Profile;

import React, { useEffect, useState, useContext } from "react";
import Tablet from "./Tablet"; // Adjust the path as needed
import products from "./staticData"; // Import the static dataset
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from "./AxiosClient";
import noteContext from "../context/notes/noteContext";

const Pricing = ({productId,product, pincode,token }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [vendors, setVendors] = useState([]); // Initialize as an empty array
    const [filteredVendors, setFilteredVendors] = useState([]);
    const [sortOption, setSortOption] = useState('distance');
    // const [vendors, setVendors] = useState([]);
    console.log(productId,product, "9999")
    const {checkTokenExpiry} = useContext(noteContext)
    useEffect(() => {
      if (pincode) {
        fetchVendorData(product.name);
      }
      
    }, [pincode,product.name, sortOption]);
  
    const handleSortChange = (e) => {
      const selectedSortOption = e.target.value;
      setSortOption(selectedSortOption);
    };
  
    const fetchVendorData = async (name) => {
      try {
  
        const formData = new FormData();
        formData.append("pincode", pincode);
  
        if (sortOption === 'price_to_high_to_low' || sortOption === 'price_to_low_to_high') {
          formData.append("search", sortOption);
        } else {
          formData.append("search", ''); // For distance option, pass empty string
        }
  
        const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
        checkTokenExpiry();
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };
  
        const response = await axiosClient.post(
          `/products/get_vendors/${name}`,
          formData,
          config
        );
  
        console.log(`Vendors sorted by ${sortOption}:`, response.data);
        setVendors(response.data.data || []);
        // const results = vendors.filter(vendor =>
        //     vendor.product_name.toLowerCase().includes(searchTerm.toLowerCase())
        // );
        // console.log(results, "=-=-=-=-=-results")
        // setFilteredVendors(results);
      } catch (error) {
        console.error(`Error fetching vendors sorted by ${sortOption}:`, error);
        toast.error("An error occurred while fetching vendors.");
      }
    };

    // useEffect(() => {
    //     // Ensure products is an array
    //     if (Array.isArray(products)) {
    //         setVendors(products);
    //     } else {
    //         console.error("Products is not an array:", products);
    //         toast.error("Failed to load products.");
    //     }
    // }, []);

    // useEffect(() => {
    //     // Filter vendors based on the search term
    //     if (Array.isArray(vendors)) {
    //         console.log(vendors, "=--==vendot--==-")
    //         const results = vendors.filter(vendor =>
    //             vendor.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //         setFilteredVendors(results);
    //     }
    // }, [searchTerm, vendors]);

  
    console.log(vendors, "=-=-=-=-=-vendor")

    return (
        <div className="p-5">
            <h1 className="text-2xl font-bold">Available Vendors</h1>
             <div className="flex justify-between px-5 py-5 rounded-lg border border-orange-500 bg-gray-100">
          <div>{pincode}</div>
          <select
            value={sortOption}
            onChange={handleSortChange}
            className="border border-gray-500 rounded-lg px-2 py-1"
          >
            <option value="distance">Distance</option>
            <option value="price_to_high_to_low">Price: High to Low</option>
            <option value="price_to_low_to_high">Price: Low to High</option>
          </select>
        </div>
            <div className="mt-5 space-y-4">
                {vendors.length > 0 ? (
                    vendors.map((vendor) => (
                        <Tablet
                            key={vendor.vendor_id} // Assuming vendor_id is unique
                            vendor={vendor}
                            productId={productId}
                            name={vendor.name}
                            packaging={vendor.packaging}
                            quantity={vendor.available_quantity}
                            currency={vendor.currency}
                            prev={vendor.prev_price}
                            mrp={vendor.Mrp}
                            save={vendor.discount_percentage} // Assuming discount_percentage exists
                        />
                    ))
                ) : (
                    <div>No products found for "{searchTerm}"</div>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default Pricing;

import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import noteContext from '../context/notes/noteContext';
import logo from '../assets/logo.png';
import pharmacyLogo from '../assets/pharmacy-logo.png';
import backgroundImage from '../assets/coupon.jpeg';
import '../Coupons.css';
import CustomDropdown from './customDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faPrint, faCommentDots } from '@fortawesome/free-solid-svg-icons';

const GetCoupons = () => {
    
    const location = useLocation();
    const { checkTokenExpiry } = useContext(noteContext);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const productDetails = location.state?.cartItems?.[0] || location.state || {};

    useEffect(() => {
        setSelectedProduct(null);
        setIsLoggedIn(true);
        setsate()


    }, [location.state]);

    










    const setsate=()=>{
        if (productDetails.name) {
            setSelectedProduct(oldproductor=>({...oldproductor,product_id: productDetails.product_id,
                vendor_id: productDetails.vendor_id,
                name: productDetails.name,
                mrp: (productDetails.mrp || 0) * (productDetails.quantity || 0),
                quantity: productDetails.quantity,
                discount: productDetails.discount,
                discounted_price: productDetails.discounted_price,
                packaging: productDetails.packaging,
                availableQuantity: productDetails.available_quantity,
                description: productDetails.description,
                contact_number: productDetails.contact_number,
                instructions: productDetails.instructions,})
                
            );
        }
        
    }

    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const handleText = () => {
        alert(`Text sent for ${selectedProduct?.name || 'product'}!`);
    };

    const handleEmail = () => {
        alert(`Email sent for ${selectedProduct?.name || 'product'}!`);
    };

    const handleDownload = () => {
        alert(`Download initiated for ${selectedProduct?.name || 'product'}!`);
    };

    const handlePrint = () => {
        window.print(); // Print the coupon
    };

    return (
        <>
            <Navbar />
            {/* {window.location.reload()} */}
            <div
                className="flex flex-col justify-center items-center overflow-y-auto"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',  // Ensure the page is at least the height of the viewport
                }}
            >
                {isLoggedIn ? (
                    <div
                        className="container mx-auto flex justify-between py-8"
                        style={{ minHeight: 'calc(100vh - 80px)' }} // Ensure it takes full screen height minus footer (if footer height is 80px)
                    >
                        <div className="w-1/3 p-4">
                            <h2 className="text-3xl font-bold text-white mt-4">
                                Here is your coupon for: <span className="text-white">{selectedProduct?.name || 'Product'}</span>
                            </h2>
                            <br />
                            <label className="block text-lg font-semibold mb-2 text-white">Select Product to Download your Discount Coupon:</label>
                            <CustomDropdown
                                options={[selectedProduct?.name]}
                                selected={selectedProduct?.name}
                                onSelect={(value) => setSelectedProduct((prev) => ({ ...prev, name: value }))}
                                className="bg-white"
                            />
                        </div>

                        {/* Right Side Coupon */}
                        <div
                            className="md:w-1/3 lg:w-1/4 mx-auto animate-celebrate"
                            style={{
                                backgroundColor: '#FFFFE0',
                                borderRadius: '16px',
                                padding: '25px',
                                marginLeft: '20px',
                                marginRight: '150px',
                                marginTop: '-50px',
                                maxWidth: '400px',
                            }}
                        >
                            <div className="shadow-md rounded-lg p-4 text-center">
                                <div className="flex items-center justify-between mb-4">
                                    <img src={logo} alt="SmartPills Logo" className="w-24" />
                                    <p className="text-gray-600 text-sm font-semibold">Coupon Date: {formattedDate}</p>
                                </div>

                                <h2 className="text-xl font-bold text-orange-500 mb-2">{selectedProduct?.name} Coupon</h2>
                                <div className="flex justify-center items-center mb-2">
                                    <p className="text-sm text-gray-600 mr-2">
                                        Quantity: {selectedProduct?.quantity || 'N/A'} | Vendor ID: {selectedProduct?.vendor_id || 'N/A'}
                                    </p>
                                    <img src={pharmacyLogo} alt="Pharmacy Logo" className="w-8" />
                                </div>

                                <p className="text-2xl font-bold text-green-500 mb-2">INR {selectedProduct?.discount || 'N/A'}</p>
                                <p className="line-through text-md text-red-500 mb-2">INR {selectedProduct?.mrp || 'N/A'}</p>
                                <p className="text-md text-green-500 font-semibold">Save {selectedProduct?.discounted_price || '0'}%</p>

                                <div className="bg-yellow-100 p-2 rounded-lg my-2 text-left text-sm">
                                    <p><strong>Product ID:</strong> {selectedProduct?.product_id || 'N/A'}</p>
                                    <p><strong>Packaging:</strong> {selectedProduct?.packaging || 'Standard Packaging'}</p>
                                    <p><strong>Available Quantity:</strong> {selectedProduct?.availableQuantity || 'N/A'}</p>
                                    <p><strong>Description:</strong> {selectedProduct?.description || 'No description available.'}</p>
                                    <p><strong>Contact:</strong> Call your pharmacy at {selectedProduct?.contact_number || 'N/A'} for more details.</p>
                                    <p><strong>Instructions:</strong> {selectedProduct?.instructions || 'Follow the instructions provided by your pharmacist.'}</p>
                                </div>

                                <p className="text-red-500 font-semibold text-sm">This coupon is valid for 48 hours only.</p>
                                <div className="flex flex-col items-center mt-6">
                                    <div className="flex justify-around w-full">
                                        <button onClick={handleText} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faCommentDots} />
                                        </button>
                                        <button onClick={handleEmail} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </button>
                                        <button onClick={handleDownload} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faDownload} />
                                        </button>
                                        <button onClick={handlePrint} className="coupon-button rounded-full gradient-bg text-white flex flex-col items-center p-4 mx-2 hover:scale-105 transition-transform duration-200">
                                            <FontAwesomeIcon icon={faPrint} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="text-red-500 text-center">Please login to see your coupons.</p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default GetCoupons;
